$container-width: 1222px;
$page-width: 1280px;

body {
    font-family: 'Microsoft Yahei UI', 'Microsoft Yahei', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-width: $page-width;
}

div{
    box-sizing: border-box;
}

header{

    .navbar{

        &:hover {
            background-color:rgba(255,255,255,0.8);
            opacity: 1 !important;
        }
    }

    &.in-screen .navbar {
        opacity: 1;
    }

    .home-page &{
        position: relative;
        height: 600px;
        background: #5b5880 url(../img/head.jpg) center no-repeat;
        background-size: cover;

        >.container{
            position: relative;
            width: $container-width;
            height: 600px;
        }
        
        .navbar{
            transition: all 0.3s ease-in-out;
            opacity: 0;
            position: fixed;
        }

        &.in-screen .navbar {
            opacity: 1;
        }
    }

    .navbar{
        z-index: 1000;
        position: static;
        width: 100%;
        height: 80px;
        background-color: rgba(255,255,255,0.7);

        .container {
            position: relative;
        }

        .logo {
            position: absolute;
            display: inline-block;
            left: 8px;
            top: 25px;
        }

        .nav-menu{
            position: absolute;
            right: 0;
            font-size: 16px;

            a{
                padding: 0 0.5em;                
                line-height: 80px;
            }
        }
    }

    .text{
        position: absolute;
        color:#fff;
        right:235px;
        bottom:235px;

        .text1{
            font-size: 48px;
            text-align: center;
            padding: 10px 0;
            text-shadow: 0 0 10px #000;
            transform: translate(0px,-10px);
            transition: all ease-in-out 3s;
        }

        .text2{
            font-size: 24px;
            text-shadow: 0 0 5px #000;
            transform: translate(0px, 8px);
            transition: all ease-in-out 3s;
        }
    }

    &.init-anim{
        .text1, .text2{
            transform: translate(0px, 0px);
        }
    }
}

a{
    color:#000;
    text-decoration: none;
}

.container{
    width:$container-width;
    margin: 0 auto;
}

ul.nav-menu{
    margin: 0;
    display: inline-block;

    >li{
        display: inline-block;

        >a{
            display: inline-block;

            &:hover{
                color: #1b8177;
            }
        }
    }
}

.btn-round{
    display: inline-block;
    width: 80px;
    height: 80px;
    background: #ddd url(../img/btn-round-bg.png) center;
    border-radius: 50%;
    border: solid 4px #d2d2d2;
    cursor: pointer;

    .selected>&{
        border-color: #489dd9;
    }

    &:hover{
        border-color: #55b9ff;
    }
}

.caption{
    transition: all 0.4s ease-in-out;
    .selected>& {
        // font-size: 22px;
        transform: scale(1.5,1.5) translate(0,8px);
    }
}

div[name="about-tbl"] {
    margin-top: 57px;
}

div.tbl{
    display: table;
    width: 660px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
}

div.tbl-row{
    display: table-row;
}


div.cell{
    display: table-cell;
    text-align: center;
    vertical-align: top;
}

div.cell-padding{
    display: table-cell;
    padding-left: 17px;
}

.caption {
    color: #727171;
    text-align: center;
    line-height: 36px;
    font-size: 16px;
}

.tab-panel{
    visibility: hidden;
    position: relative;
    font-size: 18px;
    line-height: 30px;
    font-weight: lighter;
    height: 420px;

    p{
        margin: 6px 0;
    }

    &.selected{
        visibility: visible;
    }

    .left{
        transform: translate(0px, -40px);
        // transition: all 0.3s ease-in-out;
        opacity: 0.3;
    }

    .right{
        transform: translate(80px, 0px);
        // transition: all 0.3s ease-in-out;
        opacity: 0.3;
    }
}

.tab-panel-container {
    position: relative;
    height: 420px;
    .tab-panel {
        position: absolute;
    }

    a{
        text-decoration: none;
    }
}

h1{
    margin:0;
    text-align: center;
    font-size: 38px;
    font-weight: normal;
}

h5{margin: 0;}

.detial{
    display: table;

    &>.left,&>.right  {
        vertical-align: top;
        display: table-cell;

        .selected>&{            
            transform: translate(0px, 0px);
            opacity: 1;
        }
    }
}


.img{
    text-align: center;
}

div[name="about"] {
    margin-top: 40px;

    .left{
        width: 44%;

        .img{
            padding-top:114px;
        }
    } 

    .right{
        width: 56%;
        text-indent: 2em;

        .img{
            margin-top:32px;
            text-align: center;
        }
    }
}

div[name="location"],div[name="base"] {
    h1{
        text-align: left;
    }

    .left, .right{
        position: relative;
        width: 50%;
        vertical-align: middle;
    }

    .img{
        display: inline-block;
        position: relative;
    }
}

div[name="location"]{
    .right{
        padding: 0 32px;
    }
}

footer {
    background: #959595;
    color:#fff;
    font-size: 14px;
    line-height: 24px;
    box-sizing: border-box;
    height: 160px;
    padding: 27px 25px 22px 25px ;

    >.container{
        display: table;
    }

    .col-5{
        text-align: right;
    }

    h5{
        font-size: 16px;
        font-weight: normal;
        padding-top: 7px;
    }

    img{
        position: relative;
        left: 45px;
    }

    a{
        color:#fff;

        &:hover{
            color:#1edfd4;
        }
    }
}

.row {
    display: block;
    width: 100%;

    &::after, &::before {
        content: ' ';
        display: table;
        clear: both;
        width: 0;
        height: 0;
        overflow: hidden;
    }
}

.col-1,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-10,.col-11,.col-12 {
    display: block;
    float: left;
}

.col-1 {
    width: 8.333333333333333%;
}

.col-2 {
    width: 16.666666666666666%;
}

.col-3 {
    width: 25%;
}

.col-4 {
    width: 33.333333333333333%;
}

.col-5 {
    width: 100%/12*5;
}

.col-6 {
    width: 50%;
}

.col-7 {
    width: 100%/12*7;
}

.col-8 {
    width: 100%/12*8;
}

.col-9 {
    width: 100%/12*9;
}

.col-10 {
    width: 100%/12*10;
}

.col-11 {
    width: 100%/12*11;
}

.col-12 {
    width: 100%;
}

.clear-height{
    line-height: 0;
}

.team{
    background: #f2f2f3;
    height: 360px;
    font-size: 16px;
    font-weight: lighter;

    h1{
        text-align: left;
        font-size: 24px;
        padding-bottom: 22px;
    }

    >.container {
        position: relative;

        >.left{
            position: absolute;
            left:46px;
            top:46px;
        }

        >.right {
            position: absolute;
            right: 65px;
            top:46px;
            width: 290px;
            line-height: 27px;
        }
    }

    .hslider{
        width: 768px;
        height: 226px;
    }
}

.hslider{
    position: relative;

    >.hslider-content{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        white-space: nowrap;

        >a{
            display: inline-block;
        }

        img{
            width: 336px;
            height: 226px;
        }
    }

    &:hover {
        >.prev,>.next{
            background-color: rgba(0,0,0,0.5);
        }
    }

    >.prev,>.next{

        &[disabled] {
            display: none;
            // opacity: 0.5;
            // background-color: rgba(0,0,0,0.5) !important;
        }
    }

    >.prev,>.next{
        position: absolute;
        top: 0;
        bottom: 0;
        background-repeat: no-repeat;
        background-position: center;
        transition: all 0.2s ease-in-out;
        width: 35px;
        cursor: pointer;

        &:hover{
            background-color: rgba(0,0,0,0.75);
        }
    }

    >.prev{
        left: 0;
        background-image: url(../img/triangle-left.png);
    }

    >.next{
        right: 0;
        background-image: url(../img/triangle-right.png);
    }
}

.more-img-link{
    position: relative;
    display: inline-block;

    .more-btn{
        position: absolute;
        left: 5px;
        bottom: 5px;
        border: solid 1px rgba(255,255,255,0.8);
        border-radius: 16px;
        height: 32px;
        color:#fff;
        background-color: rgba(0,0,0,0.5);
        padding: 0 1.5em;
        line-height: 30px;
        font-size: 14px;
    }
}

.partner{
    padding: 40px 0;
    margin-top: 19px;
    margin-bottom: 69px;

    p{
        margin: 0;
        text-align: center;
    }
    a{
        display: inline-block;
        margin: 25px;
    }
    img{vertical-align: middle;}
}

.supply{

    >.container {
        display: table;
    }

    h2{
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        margin-top: 2em;
        margin-bottom: 1em;

        &.green{
            color: #1b8177;
        }
        &.blue{
            color: #00a7de;
        }
    }

    h3{
        text-align: center;
        color: #00736d;
        font-size: 26px;
        font-weight: normal;
        margin: 0;
        padding-bottom: 7px;
        border-bottom: solid 2px #00736d;

        img{
            vertical-align: middle;
        }
    }

    .cell-board{
        display: inline-block;
        width: 245px;
        height: 168px;
        border-radius: 30px;
        background-color: #ddeabd;
        color: #000;
        padding: 20px 10px;
    }

    .middle{
        display: table-cell;
        height: 95px;
        vertical-align: middle;
    }

    li{
        font-size: 18px;
        line-height: 25px;
        text-align: left;
    }

    ul{
        vertical-align: middle;
        margin: 0;
    }

    .btn{
        position: relative;
        display: inline-block;
        width: 365px;
        height: 83px;
        line-height: 83px;
        font-size: 26px;
        background-color: #00b7ee;
        color: #fff;
        margin: 14px 10px;

        &:hover{
            font-weight: bold;

            >.tip{
                display: block;
            }
        }

        >.tip{
            display: none;
            font-weight: normal;
            text-align: left;
            position: absolute;
            left: -220px;
            top: -10px;
            color: #1368aa;
            background-color: #d9f4fc;
            font-size: 18px;
            line-height: 25px;
            width: 214px;
            height: 102px;
            padding: 12px 16px;
        }

        >.tip-same {
            padding-left: 24px;
            padding-right: 24px;
        }

        >img{
            vertical-align: middle;
            margin-right: 1em;
        }
    }

    .tbl-row .cell:last-child .btn>.tip
    {
        left: 370px;
    }
}

.vl {
    display: block;
    width: 100%;
    border-top: solid 1px #a0a0a0;
    margin-top: 30px;
    margin-bottom: 50px;
}

.financial{
    height: 650px;
    background: url(../img/financial.jpg) no-repeat center;
    background-size: cover;

    >.container {
        position: relative;
        height: 650px;        
    }

    h1{
        font-weight: lighter;
        padding-top: 120px;
        transform: translate(0px,-24px);
        transition: all 2s ease-in-out;
    }

    .btn{
        position: absolute;
        left: 515px;
        bottom: 45px;
        font-size: 24px;
        font-weight: lighter;
        color: #fff;
        background: #d44859 url(../img/triangle2.png) no-repeat 160px center;
        line-height: 50px;
        border-radius: 25px;
        padding: 0 50px;
    }

    .flist{
        position: absolute;
        opacity: 0;
        transition: all 2s ease-in-out;
        left: -44px;
        top: 92px;
        background-color: rgba(0,0,0,0.3);
        color: white;
        padding-right: 1em;
        font-size: 24px;
        font-weight: lighter;
        line-height: 38px;

        li {
            transition: all 0.2s ease-in-out;
            transform: translate(0px, 8px);
            opacity: 0;
        }

        li:nth-child(1) {
            transition-delay: 1.4s;
        }
        li:nth-child(2) {
            transition-delay: 1.6s;
        }
        li:nth-child(3) {
            transition-delay: 1.8s;
        }
        li:nth-child(4) {
            transition-delay: 2s;
        }
    }

    .play-mask{
        display: none;
        position: absolute;
        left:0;
        right:0;
        top:0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 200;
    }

    .playbox{
        position: absolute;
        background-color: #000;
        left:50%;
        top:50%;
        margin-left: -300px;
        margin-top: -225px;

        >.btn-close{
            position: absolute;
            right:-64px;
            top:0;
            border: none 0;
            background: #000;
            color: #fff;
        }
    }
}



.in-screen.financial{
    h1{
        transform: translate(0px,0px);
    }
    .flist{
        opacity: 1;

        li{       
            transform: translate(0px, 0px);     
            opacity: 1;
        }
    }
}

#location_slider{
    display: none;
    position: absolute;
    width: 346px;
    height: 197px;
    left: 55px;
    top: -30px;

    .hslider-content{
        left: 36px;
        right: 36px;
    }

    >.prev,>.next{
        background-color: rgba(0,0,0,0.3);
    }

    img{
        width: 274px;
        height: 197px;
    }
}

.hotpoint{
    position: absolute;
    width: 17px;
    height: 17px;
    background-color: #18bef0;
    opacity: 0.5;
    border-radius: 50%;

    transform-origin: center;
    animation: hotpointAnim 1s ease-in-out infinite alternate;
}

@keyframes hotpointAnim {
    0% { transform: scale(1); }
    100% { transform: scale(2); }
}

.news-link{
    display: block;
    overflow:hidden; 
    width: 290px;
    height: 27px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.breadcrumbs{
    display: block;
    background: #f2f2f3;
    padding: 0 12px;
    line-height: 30px;
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding:0 2em;

    >li{
        display: inline-block;

        &:after {
            content: '>';
            display: inline-block;
            padding-left: 0.2em;
        }

        &:last-child:after {
            display: none;
        }
    }
}

.news-page>.content{
    a{
            color:#595757;
    }
}

.news-list {
    color: #595757;
    margin: 0;
    padding:0;
    font-size: 24px;
    
    >li{
        display: block;
        position: relative;
        margin-left: 157px;
        padding-top: 75px;
        padding-bottom: 50px;
        border-bottom: dotted 1px #595757;

        .date {
            color:#595757;
            margin-top: 8px;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.pager{
    color: #595757;
    font-size: 14px;
    margin: 1em 157px;

    .active{
        font-weight: bold;
    }

    a{
        color: #595757;
        padding:0 0.3em;
    }
}

.new-page{
    color: #595757;
    h1 {
        font-size: 24px;
        margin-top: 1em;
        margin-bottom: 1em;

        >small {
            display: block;
            margin-top: 8px;
            font-size: 16px;
        }
    }
    .news-content {
        width: 912px;
        margin: 50px auto 75px auto;

        h1{
            margin-top:0;
        }

        p{
            line-height: 29px;
            font-size: 16px;
            text-indent: 2em;
        }

        .img{
            display: block;
            margin: 2em 0;
            text-align: center;

            >img{
                max-width: 100%;
            }

            >span{
                display: block;
                font-style: italic;
                font-size: 14px;
                padding-top: 12px;
            }
        }
    }
}



@media only screen and (max-device-width: $page-width) {  

    html {
        width:$page-width; 
        margin: 0 auto;
        overflow-x:hidden;
    }

    

header .navbar
    {
        max-width: $page-width;
    }
}